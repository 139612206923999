.popup_area {
  padding: 30px;
}

.popup_area .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup_area .header .title {
  color: #192B57;
    font-family: 'Noto Sans KR', sans-serif;
  font-size: 40px;
}
.popup_area .header .close {
  width: 30px;
  height: 30px;
  background: url('assets/images/pop_close.png') no-repeat;
}

.popup_area .content {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 24px;
  line-height: 26px;
  position: relative;
}

.popup_area .content .table_area {
  margin-top: 10px;
}

.popup_area .content .item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.popup_area .content .item.message {
  text-align: center;
}

.popup_area .content .item .media {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.popup_area .content .item .media img {
  width: 100%;
}

.popup_area .content .input {
  width: 100%;
  height: 64px;
  padding: 20px;
  font-size: 24px;
  box-sizing: border-box;
  border: 1px solid #bbc4d7;
  border-radius: 5px;
}

.popup_area .content .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
}
.popup_area .content .inner::-webkit-scrollbar {
  width: 8px;
}
.popup_area .content .inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(196, 196, 196, 0.5);
}

.popup_area .footer {
  display: flex;
  justify-content: center;
}
.popup_area .footer .btn {
  width: 174px;
  height: 80px;
  font-size: 26px;
  border-radius: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.popup_area .footer .btn.confirm {
  color: #fff;
  background-color: #192b57;
}

.popup_area .footer .btn.cancel {
  color: #666;
  background-color: #fff;
  border: 2px solid #abbfc6;
}
/* 
.thanks_popup_area {
} */

.thanks_popup_area .header {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thanks_popup_area .header .title {
  color: #0c1622;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 40px;
}
.thanks_popup_area .header .close {
  width: 30px;
  height: 30px;
  background: url('assets/images/pop_close.png') no-repeat;
}

.thanks_popup_area .content {
  padding: 30px;
  font-size: 24px;
  line-height: 34px;
  position: relative;
  background: url('assets/images/bg_greeting.jpg') no-repeat top #f1f1f1;
}

.thanks_popup_area .content .title {
  margin-bottom: 12px;
  color: #fff;
  font-size: 32px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
}

.thanks_popup_area .content .template .list {
  padding-bottom: 84px;
}
.thanks_popup_area .content .template .list li {
  padding: 20px;
  background: #f5f5f5;
  border-bottom: 1px solid #273862;
}

.thanks_popup_area .content .edit_box {
  margin: 56px 0 44px;
  border: 1px solid #bbc4d7;
  background-color: #fff;
}

.thanks_popup_area .content .edit_box .header {
  font-size: 32px;
  border-bottom: 1px solid #bbc4d7;
}

.thanks_popup_area .content .edit_box .text_area {
  padding: 30px;
}
.thanks_popup_area .content textarea {
  width: 100%;
  height: 100%;
}

.thanks_popup_area .content .edit_box .content {
  font-size: 26px;
}

.thanks_popup_area .message_area {
  text-align: center;
}

.thanks_popup_area .message_area a {
  display: inline-block;
  margin: 0 40px;
  color: #0c1622;
  font-size: 30px;
  font-family: 'Noto Sans KR', sans-serif;
}
.thanks_popup_area .message_area a:before {
  display: block;
  width: 85px;
  height: 72px;
  margin: 0 auto 14px;
  background-repeat: no-repeat;
  background-size: 100%;
  content: '';
}
.thanks_popup_area .message_area a.kakao:before {
  background-image: url('assets/images/bg_message1.png');
}
.thanks_popup_area .message_area a.sms:before {
  background-image: url('assets/images/bg_message2.png');
}

    /* 2022-08-20[s] */
    @media (max-width: 760px) {
      .popup_area .header {
        justify-content: center;
      }
  
      .popup_area .header .close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 15px;
        height: 15px;
        background-size: cover;
      }
  
      .popup_area .header .title {
        font-size: 21px;
      }
  
      
  
      .popup_area .footer .btn {
        font-size: 16px;
        font-weight: bold;
        width: 25%;
        height: 40px;
        font-weight: bold;
      }
    }
  
    /* 2022-08-20[e] */