.content-body img {
  max-width: 100%;
}

@media (max-width: 480px) {
  .mo_button_grid {
    justify-content: center !important;
  }
  .mo_button_grid .mo_button_box > button,
  .mo_button_grid .mo_button_box > a {
    padding: 8px 40px;
  }

  .mo_blts_box {
    justify-content: center !important;
  }
  .mo_blts_box > button {
    padding: 8px 15px;
  }

  .mo_blts_write {
    margin: 8px 0 !important;
  }
  .mo_blts_write .mo_blts_write_top {
    flex-wrap: wrap;
  }
  .mo_blts_write .mo_blts_write_top > .mo_blts_write_subject {
    width: 100%;
    margin-bottom: 8px;
  }
}

/*paging*/
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 40px;
  height: 30px;
  border: 0px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}
ul.pagination li a {
  margin: 0 14px;
  font-size: 18px;
  color: #a8b3c3;
}
ul.pagination li.active a {
  color: #0c1622;
}

ul.pagination li.active {
  background-color: transparent;
}
ul.pagination li a:hover,
ul.pagination li a.active {
  color: #a8b3c3;
}
ul.pagination .active a:hover {
  color: #0c1622;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}
.content_wrap .pagination .prev {
  background-image: url('assets/images/arr_paging_prev.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.content_wrap .pagination .next {
  background-image: url('assets/images/arr_paging_next.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.content_wrap .pagination .first {
  background-image: url('assets/images/ico_pre2.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.content_wrap .pagination .last {
  background-image: url('assets/images/ico_next2.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

/* content loading start */

.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

.loading_inner {
  width: 80%;
}

/* content loading end */

/* admin custom css start */

.admin_button_wrap {
  text-align: center;
}
.admin_button {
  width: 200px;
  height: 50px;
  color: #fff;
  background-color: #192b57;
  border-radius: 50px;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.admin_button.cancel {
  background-color: #fff;
  color: #666;
  border: 1px solid #abbfc6;
}

/* admin custom css end */

/* app css start */

.outlet_wrap {
  min-height: 100vh;
}

/* app css end */

.no_data {
  width: 100%;
  margin-top: 100px;
  text-align: center;
}

/*main page 부고 start*************************************************************************/
.memorial_section_main:before {
  background: transparent !important;
}

.memorial_section_main {
  overflow: visible !important;
}

.memorial_section .memorial_slide_main {
  margin-top: 40px;
}
.memorial_section .memorial_slide_main .swiper-slide {
  /*height: 400px;*/
  transform: scale(0.8);
}
.memorial_section .memorial_slide_main .swiper-slide-active {
  margin-top: -55px; /*height: 575px;*/
  transform: scale(1);
}
.memorial_section .memorial_slide_main .img_area {
  height: 490px;
}
.memorial_section .memorial_slide_main .text {
  margin-top: 40px;
  margin-bottom: 13px;
  font-size: 28px;
}
.memorial_section .memorial_slide_main .info {
  color: #7c7c7c;
  font-size: 22px;
}
.memorial_section .memorial_slide_main dt {
  margin-bottom: 17px;
  font-family: 'NotoSerifKR';
}
.memorial_section .memorial_slide_main dd {
  margin-bottom: 6px;
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif;
}
.memorial_section .memorial_slide_main dd span {
  display: inline-block;
  width: 57px;
  height: 30px;
  margin-right: 14px;
  border: 1px solid #0c1622;
  color: #0c1622;
  border-radius: 50px;
  text-align: center;
}

/*추모관*/
.intro_slide {
  height: 697px;
}

/*부고*/
.memorial_slide_main {
  height: 705px;
}

.death_cotent_area {
  position: relative;
  z-index: 5;
}

.tab_content_inner {
  position: relative;
  z-index: 5;
}

.memorial_slide {
  height: 474px;
}

.editor_viewer_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.editor_viewer_wrap .editor_viewer_inner {
  max-width: 736px;
}

@media (max-width: 760px) {
  /*부고 상세 추도사 bottom*/
  .section_style_view {
    margin-bottom: 0px !important;
  }

  /*부고 상세 디지털화환 bottom*/
  .memorial_section .memorial_slide {
    padding-bottom: 0px !important;
  }

  .memorial_slide {
    height: 255px !important;
  }

  /*부고 상세 tab 지도영역*/
  #tab_cont .content_infor_01 .map_area {
    height: 460px !important;
  }

  /*추모관*/
  .intro_slide {
    height: 383px;
  }

  .swiper {
    margin-left: 0;
  }

  /*부고*/
  .memorial_slide_main {
    height: 339px;
  }

  .memorial_section .memorial_slide_main {
    margin-top: 0px;
    padding-bottom: 60px;
  }
  .memorial_section .memorial_slide_main .swiper-slide {
    height: auto;
    transform: scale(0.9);
  }
  .memorial_section .memorial_slide_main .swiper-slide-active {
    margin-top: -23px;
    height: auto;
    transform: scale(1.2);
  }
  .memorial_section .memorial_slide_main .swiper-slide img {
    width: 100%;
  }
  .memorial_section .memorial_slide_main .img_area {
    height: 196px;
  }
  .memorial_section .memorial_slide_main .text {
    margin-top: 22px;
    margin-bottom: 4px;
    font-size: 18px;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .memorial_section .memorial_slide_main .info {
    line-height: 1.4;
    font-size: 14px;
  }
  .memorial_section .memorial_slide_main dd {
    font-size: 14px;
  }
  .memorial_section .memorial_slide_main dd span {
    width: 45px;
    height: 18px;
    margin-right: 7px;
    font-size: 12px;
  }

  ul.pagination li a {
    font-size: 10px;
  }

  ul.pagination li {
    width: 15px;
  }

  .content_wrap .pagination .prev {
    background-size: 5px;
  }
  .content_wrap .pagination .next {
    background-size: 5px;
  }

  .register .bt_opt {
    bottom: 30px;
  }
}

/*main page 부고 end*************************************************************************/
