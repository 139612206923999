/* Set content font-families */
@font-face {
  font-family: 'AritaBuri';
  src: url('assets/font/AritaBuriB.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'AritaBuri-Semi';
  src: url('assets/font/AritaBuriSB.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'NotoSerifKR';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NotoSerifKR.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
/* S : 22-11-29 폰트 추가 */
@font-face {
  font-family: 'ChosunSm';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.1/ChosunSm.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ChosunSg';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunSg.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KOTRA_SONGEULSSI';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/KOTRA_SONGEULSSI.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hankc';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/hani/Hankc/Hankc.woff2') format('woff2'),
    url('//cdn.jsdelivr.net/korean-webfonts/1/corps/hani/Hankc/Hankc.woff') format('woff');
}
@font-face {
  font-family: 'Humanbumsuk';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-2@1.0/Humanbumsuk.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MapoFlowerIsland';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoFlowerIslandA.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RIDIBatang';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/RIDIBatang.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SeoulNamsanM';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/SeoulNamsanM.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
/* E : 22-11-29 폰트 추가 */
.ql-container {
  font-family: 'Noto Sans KR';
}

.ql-snow .ql-picker.ql-font {
  width: 130px;
}

.ql-font-NotoSansKR {
  font-family: 'Noto Sans KR';
}

.ql-font-AritaBuri {
  font-family: 'AritaBuri';
}
.ql-font-AritaBuri-Semi {
  font-family: 'AritaBuri-Semi';
}
.ql-font-NotoSerifKR {
  font-family: 'NotoSerifKR';
}
.ql-font-ChosunSm {
  font-family: 'ChosunSm';
}
.ql-font-ChosunSg {
  font-family: 'ChosunSg';
}
.ql-font-KOTRA_SONGEULSSI {
  font-family: 'KOTRA_SONGEULSSI';
}
.ql-font-Hankc {
  font-family: 'Hankc';
}
.ql-font-Humanbumsuk {
  font-family: 'Humanbumsuk';
}
.ql-font-MapoFlowerIsland {
  font-family: 'MapoFlowerIsland';
}
.ql-font-RIDIBatang {
  font-family: 'RIDIBatang';
}
.ql-font-SeoulNamsanM {
  font-family: 'SeoulNamsanM';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='NotoSansKR']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='NotoSansKR']::before {
  content: 'NotoSansKR';
  font-family: 'Noto Sans KR' !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='AritaBuri']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='AritaBuri']::before {
  content: 'AritaBuri';
  font-family: 'AritaBuri' !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='AritaBuri-Semi']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='AritaBuri-Semi']::before {
  content: 'AritaBuri-Semi';
  font-family: 'AritaBuri-Semi' !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='NotoSerifKR']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='NotoSerifKR']::before {
  content: 'NotoSerifKR';
  font-family: 'NotoSerifKR' !important;
}

/* S : 22-11-29 폰트 추가 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='ChosunSm']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='ChosunSm']::before {
  content: 'ChosunSm';
  font-family: 'ChosunSm' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='ChosunSg']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='ChosunSg']::before {
  content: 'ChosunSg';
  font-family: 'ChosunSg' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='KOTRA_SONGEULSSI']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='KOTRA_SONGEULSSI']::before {
  content: 'KOTRA_SONGEULSSI';
  font-family: 'KOTRA_SONGEULSSI' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Hankc']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Hankc']::before {
  content: 'Hankc';
  font-family: 'Hankc' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Humanbumsuk']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Humanbumsuk']::before {
  content: 'Humanbumsuk';
  font-family: 'Humanbumsuk' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='MapoFlowerIsland']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='MapoFlowerIsland']::before {
  content: 'MapoFlowerIsland';
  font-family: 'MapoFlowerIsland' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='RIDIBatang']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='RIDIBatang']::before {
  content: 'RIDIBatang';
  font-family: 'RIDIBatang' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='SeoulNamsan']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='SeoulNamsan']::before {
  content: 'SeoulNamsanM';
  font-family: 'SeoulNamsanM' !important;
}
/* E : 22-11-29 폰트 추가 */

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-size-Header1 {
  font-size: 36px !important;
}

.ql-size-Header2 {
  font-size: 30px !important;
}

.ql-size-Content1 {
  font-size: 28px !important;
}

.ql-size-Content2 {
  font-size: 24px !important;
}

.ql-size-Content3 {
  font-size: 20px !important;
}
.ql-snow p,
.ql-editor p {
  font-size: 18px !important;
}
.ql-editor em {
  font-style: italic;
}
.ip-item .ql-container.ql-snow,
.box-editor .ql-container.ql-snow {
  min-height: calc(50vh - 40px);
  overflow-y: visible;
}

.ip-item .ql-container.ql-snow .ql-editor,
.box-editor .ql-container.ql-snow .ql-editor {
  overflow: visible;
  min-height: calc(50vh - 40px);
}
