/* swiper custom css*/

/*이미지 상단 overflow 수정*/
.swiper {
  overflow: visible;
}

.swiper-button-prev {
  background-image: url();
}

.swiper-button-next {
  background-image: url();
}
